@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f5f5fd; */
  background-color: #ffffff;
  font-family: "Poppins" !important;
}
a {
  font-family: "Poppins" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pointer {
  cursor: pointer;
}

.rotate-anim {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 500ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 500ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 500ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* removed bootstrap */

[hidden] {
  display: none;
}

/*Logo*/

.logo {
  /* position: absolute;
  z-index: 9; */
  border-bottom: 1px solid #ececf6;
  /* box-shadow: 0 0 8px #0909096e; */
  padding: 13px 30px;
  background-color: #f2ffffcc;
  width: 100%;
  /* min-height: 55px; */
  display: flex;
  justify-content: center;
}

.logo img {
  max-width: 130px;
  /* padding: 10px 0px 0px 3px; */
  height: auto;
}

.small-logo {
  position: absolute;
  z-index: 9;
  border-bottom: 1px solid #ececf6;
  padding: 30px 30px 30px 30px;
  background-color: white;
  width: 249px;
  min-height: 55px;
  display: block;
}

.small-logo img {
  width: 120px;
  padding: 10px 0px 0px 3px;
}

.menu-drawer-close .logo {
  width: 250px;
  overflow: hidden;
  padding: 30px 8px;
  display: block;
}

.menu-drawer-close .logo:hover {
  width: 250px;
}

/*Top Bar*/

.MuiAppBar-colorPrimary {
  color: #fff;
  /* background-color: #427D9D; */
  background-color: white;
  box-shadow: none;
  border-bottom: 1px solid #f5f5fd;
  height: 97px;
}

.menu-drawer-open {
  width: 250px;
}

.appbar-main .appbar-shift {
  margin-left: 145px;
  width: calc(100% - 250px);
  transition: 0.5s;
  padding: 10px 30px;
  height: auto !important;
  box-shadow: 0 0 8px #0909096e;
  background: #fafafafa !important;
  z-index: 999;
}

.appbar-main header {
  padding: 20px 30px;
}

.MuiToolbar-regular {
  min-height: 52px;
}

.mui-fixed button,
.mui-fixed button:hover {
  width: 36px;
  height: 36px;
  border-radius: 90px;
  background-color: #e3e3f1;
  color: #344750;
  margin-right: 0px;
}

.MuiToolbar-gutters {
  padding-left: 15px;
}

.MuiListItemIcon-root {
  min-width: 40px;
}

.page-title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #2b3f57;
  letter-spacing: 0.5px;
  padding-left: 15px;
}

/*menu*/

.sidemenu-basic {
  padding-top: 30px;
}

.sidemenu-basic a {
  padding-left: 12px;
  margin: 0px auto;
  width: 92%;
  border-radius: 90px;
}

.logout-menu a {
  padding-left: 50px;
  margin: 0px auto;
  width: 92%;
  border-radius: 90px;
}

.logout-menu a span,
.sidemenu-basic a span {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #8b8bad;
  line-height: 24px;
  text-transform: uppercase;
}

.logout-menu a i,
.sidemenu-basic a i {
  font-size: 21px;
  position: absolute;
  left: 15px;
  top: 10px;
  color: #697f92;
}

.logout-menu a.Mui-selected,
.logout-menu a.Mui-selected:hover,
.logout-menu a.Mui-selected:focus,
.dropdown-menu a.Mui-selected,
.dropdown-menu a.Mui-selected:hover,
.dropdown-menu a.Mui-selected:focus,
.sidemenu-basic a.Mui-selected,
.sidemenu-basic a.Mui-selected:hover,
.sidemenu-basic a.Mui-selected:focus {
  color: #112268;
  background-color: #fff;
  box-shadow: 0px 5px 7.2px 0.8px rgba(126, 149, 184, 0.1);
  border-radius: 90px;
  margin: 1px auto 1px;
  width: 92%;
}

.logout-menu a:hover,
.logout-menu a:focus,
.dropdown-menu a:hover,
.dropdown-menu a:focus,
.sidemenu-basic a:hover,
.sidemenu-basic a:focus {
  color: #112268;
  background-color: #fff;
  box-shadow: 0px 5px 7.2px 0.8px rgba(126, 149, 184, 0.1);
  border-radius: 90px;
  margin: 0px auto;
  width: 92%;
}

.logout-menu a.Mui-selected span,
.logout-menu a.Mui-selected:hover span,
.dropdown-menu a.Mui-selected span,
.dropdown-menu a.Mui-selected:hover span,
.sidemenu-basic a.Mui-selected span,
.sidemenu-basic a.Mui-selected:hover span {
  color: #112268;
  font-weight: 500;
}

.logout-menu a.Mui-selected i,
.logout-menu a.Mui-selected:hover i,
.logout-menu a.Mui-selected:focus i,
.dropdown-menu a.Mui-selected i,
.dropdown-menu a.Mui-selected:hover i,
.dropdown-menu a.Mui-selected:focus i,
.sidemenu-basic a.Mui-selected i,
.sidemenu-basic a.Mui-selected:hover i,
.sidemenu-basic a.Mui-selected:focus i {
  color: #4a2cda;
}

/*Dropdown menu*/

.dropdown-menu .MuiCollapse-wrapper {
  background-color: #e6e6f3;
}

.dropdown-menu {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

/* .menu-drawer-close .dropdown-menu span.MuiTypography-root {
  display: none;
} */

.dropdown-menu i {
  font-size: 21px;
  position: absolute;
  left: 0px;
  top: 11px;
}

.dropdown-menu .MuiList-root a {
  margin: 0px auto;
  width: 90%;
  padding-left: 10px;
  border-radius: 90px;
}

.dropdown-menu .MuiList-root a i {
  left: 16px;
}

.dropdown-menu span {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #8b8bad;
  line-height: 24px;
  text-transform: uppercase;
}

.dropdown-menu .Mui-selected {
  padding-right: 16px;
  padding-left: 23px;
}

.dropdown-menu svg {
  color: #697f92;
  font-size: 18px;
}

.menu-drawer-open .dropdown-menu .MuiList-root a {
  width: 92%;
  margin: 0 auto 1px;
}

.menu-drawer-open .dropdown-menu .MuiList-root a i {
  font-size: 21px;
  position: absolute;
  top: 10px;
  color: #697f92;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: transparent;
}

/*menu drawer close*/

.menu-drawer-close {
  width: 52px;
  z-index: 9;
  bottom: 0;
  float: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  background-color: white;
}

.menu-drawer-close:hover {
  width: 250px;
  z-index: 99;
}

.menu-drawer-close .dropdown-menu svg {
  display: none;
}

.menu-drawer-close:hover .dropdown-menu svg {
  display: block;
}

.mui-fixed {
  width: calc(100% - 52px);
  z-index: 10;
}

.menu-drawer-close .dropdown-menu .MuiList-root a {
  padding-left: 10px;
}

.menu-drawer-close .dropdown-menu .MuiList-root a i {
  left: 52px;
}

.drawer-open .content-wrapper-inner,
.drawer-open .content-wrapper {
  padding: 40px 40px 40px 40px;
}

.menu-drawer-close .sidemenu-basic a i {
  left: 12px !important;
}

.menu-drawer-close .dropdown-menu .MuiListItem-button i {
  left: 12px !important;
}

.menu-drawer-close:hover
  .dropdown-menu
  .MuiListItem-button
  .MuiListItemIcon-root
  i {
  left: 24px !important;
}

.menu-drawer-close:hover
  .dropdown-menu
  .MuiCollapse-container
  .MuiList-root
  a
  i {
  left: 12px !important;
}

.menu-drawer-open .dropdown-menu i {
  left: 28px;
}

.menu-drawer-open .dropdown-menu .MuiListItem-gutters {
  padding-left: 12px;
}

.menu-drawer-open .dropdown-menu .icon-admin {
  padding-left: 20px;
}

.menu-drawer-close .dropdown-menu .MuiListItem-gutters {
  padding-left: 10px;
}

/*menu vertical scroll*/

.MuiDrawer-root .MuiDrawer-paper::-webkit-scrollbar {
  display: none;
}

.MuiDrawer-root .MuiDrawer-paper {
  white-space: nowrap;
  height: 100%;
  overflow-y: auto;
}

/*Top Profile*/

.username-tx {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding: 14px 10px 14px;
  line-height: 32px;
  color: #f3f3f3 !important;
}

.MuiAvatar-colorDefault {
  width: 32px;
  height: 32px;
  background-color: #d4d61f;
}

/*breadcrumb-nav*/

nav .breadcrumb-ul {
  width: auto;
  margin: 0px;
  padding: 0px 0px 30px;
  list-style: none;
}

nav .breadcrumb-ul li {
  display: inline-block;
  position: relative;
  padding: 0px 15px 0px 10px;
}

nav .breadcrumb-ul li:first-child {
  padding: 0px 15px 0px 0px;
}

/* nav .breadcrumb-ul li a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #949ca3;
  display: inline-block;
  text-decoration: none;
} */

/* nav .breadcrumb-ul li:first-child::before {
  content: '';
  display: block;
  background-color: #84cf6e;
  width: 8px;
  height: 8px;
  border-radius: 90px;
  position: absolute;
  left: 0px;
  top: 7px;
} */

nav .breadcrumb-ul li a::before {
  content: "/";
  /* font-family: 'dashboard-icons'; */
  font-size: 16px;
  color: #949ca3;
  position: absolute;
  top: -2px;
  right: 0px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}

nav .breadcrumb-ul li.disabled a {
  /* opacity: 0.8; */
  cursor: inherit;
  color: #21c4d3;
}

nav .breadcrumb-ul li.disabled a::before {
  content: "";
  display: none;
}

/*Content Area*/

.drawer-close {
  padding: 0px 40px 0px 95px;
  width: calc(100% - 250px);
}

.drawer-open {
  padding: 0px 40px 0px 40px;
}

.contenter-main {
  padding: 0px;
  width: calc(100% - 250px);
}

.contenter-main .MuiBox-root {
  padding: 0px;
  margin: 0px 0px 20px;
}

.contenter-main .MuiBox-root:after {
  content: "";
  display: block;
  clear: both;
  height: auto;
}

.contenter-main .contant-box {
  background-color: transparent;
  box-shadow: none;
}

.content-wrapper-inner {
  width: calc(100% - 0px);
  padding: 40px 40px 40px 95px;
  transition: 0.1s;
}

.white-bg {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1.5px 2.598px 4px 0px rgba(0, 28, 61, 0.01);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(220, 228, 237);
  padding: 30px;
  margin-bottom: 50px;
}

.white-bg-form {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1.5px 2.598px 4px 0px rgba(0, 28, 61, 0.01);
  padding: 45px 40px 32px;
  margin-bottom: 0px;
  position: relative;
}

/*Table*/

.table-list {
  box-shadow: none;
  overflow: hidden;
  overflow-x: auto;
  background-color: transparent;
}

.table-list table {
  border-collapse: separate;
  border-spacing: 0 3px;
}

.table-list table thead tr {
  border-bottom: 10px solid #f5f5fd;
}

.table-list table thead tr th {
  /* background-color: #e3e3f1; */
  background-color: white;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #969fac;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0.5px;
  line-height: 18px;
  padding: 0.75rem !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.table-list table thead tr th span {
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  color: #969fac;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 18px;
  padding: 0px 0px !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

/* .table-list table thead tr th svg {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%, 0%);
} */

.table-list table thead tr th:first-child {
  border-radius: 6px 0 0 6px;
}

.table-list table thead tr th:last-child {
  width: 100px;
  text-align: center;
  border-radius: 0 6px 6px 0;
}

.table-list table tbody {
  border-radius: 4px;
}

.table-list table tbody tr {
  background-color: #ffffff;
  margin: 0px 0px 0px 0px;
  border: 1px solid #e7ecf2;
  border-collapse: collapse;
  border-radius: 4px;
}

.table-list table tbody tr:hover {
  background-color: #f0f1f3;
}

.table-list table tbody tr td {
  background-color: #fff;
  width: auto;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #677982;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0px;
  margin: 0px 0px 0px 0px;
  padding: 15px !important;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  border-spacing: 3px;
}

.table-list table tbody tr:last-child td {
  border-bottom: none;
}

.table-list table tbody tr td:first-child {
  overflow: inherit;
  border-radius: 6px 0px 0px 6px;
}

.table-list table tbody tr td:last-child {
  width: 100px;
  text-align: center;
  overflow: inherit;
  border-radius: 0px 6px 6px 0px;
}

.table-checkbox .MuiButtonBase-root {
  padding: 0px;
}

.table-list table {
  width: 100% !important;
}

/* for resize  */

.grip-handle .grip-resizable {
  z-index: 1;
}

.grip-handle:after {
  content: "";
  z-index: 0;
  opacity: 1;
  top: 12px;
  right: 0;
  width: 1px;
  height: 25px;
  position: absolute;
  border-right: 1px solid #babad0;
}

.grip-handle:last-child:after {
  content: "";
  z-index: 0;
  opacity: 1;
  top: 12px;
  right: 0;
  width: 1px;
  height: 25px;
  position: absolute;
  border-right: 0px solid #babad0;
}

.grip-resizable > tbody > tr > td,
.grip-resizable > tbody > tr > th {
  overflow: inherit !important;
}

/* Table action icons*/

.table-list table tbody tr td a {
  padding: 0px;
  text-decoration: none;
  font-size: 16px;
}

.table-list table tbody tr td span i {
  font-size: 16px;
  line-height: 9px;
}

.table-list table tbody tr td span i:first-child {
  margin-right: 5px;
}

.table-list table tbody tr td span i.dashboardicon-action {
  font-size: 16px;
  line-height: 9px;
}

.table-list table tbody tr td span i.dashboardicon-action:hover {
  color: #3f8cf4;
}

.table-list table tbody tr td .action-button {
  padding: 0 12px;
}

.table-list table tbody tr td .action-button:hover,
.table-list table tbody tr td .action-button:focus,
.table-list table tbody tr td .action-button:active {
  background-color: transparent;
}

/*table Highlight text in bold*/

.table-list table tbody tr td.text-bold {
  text-decoration: none;
}

.table-list table tbody tr td.text-link a {
  font-family: "Roboto", sans-serif;
  color: #232323;
  font-weight: 500;
  font-size: 17px;
  text-decoration: none;
}

/*Action Dropdown*/

.action-dropdown {
  z-index: 6;
}

.action-dropdown ul {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(226, 231, 237);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.02);
  padding: 0px;
}

.action-dropdown ul li {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #4c6173;
  line-height: 15px;
  padding: 8px 25px 8px 15px;
}

.action-dropdown ul li:hover {
  background-color: #eef1f5;
}

.action-dropdown ul li.Mui-selected,
.action-dropdown ul li.Mui-selected:hover {
  background-color: #eef1f5;
}

.action-dropdown ul li .MuiListItemIcon-root {
  min-width: auto;
  padding-right: 10px;
}

/* .acton-butn a, .acton-butn button {
  padding: 0px;
} */

/*Buttons*/

.form-button-wrap {
  padding-top: 30px;
}

.view-butn {
  border: 1px solid #ccc;
  padding: 3px 15px !important;
  border-radius: 15px;
  text-align: center;
  color: #6e8496;
  text-decoration: none;
}

.add-butn {
  padding: 6px 20px;
  background-color: transparent;
  border: 1px solid rgb(227, 227, 227);
  /* border-radius: 90px; */
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #3f50b5;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  box-shadow: none;
  margin-bottom: 1rem;
  border: 1px solid #3f50b5;
}

.add-butn:hover,
.add-butn:focus,
.add-butn:active {
  color: #fff;
  background-color: #42afc2;
  border: 1px solid rgb(227, 227, 227);
  box-shadow: none;
}

.content-wrapper-inner .add-butn span i {
  font-size: 13px;
  padding: 2px 10px 0px 0px;
  line-height: 14px;
}

.form-button {
  /* background-color: #4f4b4a; */
  border: 1px solid black;
  border-radius: 90px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: black !important;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.5px;
  box-shadow: none;
  padding: 6px 40px;
  text-transform: none;
  cursor: pointer;
  margin: 0px auto;
  width: auto;
}

.form-button:hover,
.form-button:focus,
.form-button:active {
  /* background-color: #112268 !important;
  border: 1px solid #112268; */
  color: #fff;
  box-shadow: none;
}

.MuiButton-contained.Mui-disabled {
  background-color: #112268;
  border: 1px solid #112268;
  opacity: 0.5;
  color: #fff;
  width: 100%;
}

.cancel-button {
  background-color: #fff;
  border: 0px solid #dbe2e9;
  border-radius: 90px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #6e8496;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.5px;
  box-shadow: none;
  padding: 8px 40px;
  margin: 8px 0px 8px;
  text-transform: none;
  cursor: pointer;
}

.cancel-button:hover,
.cancel-button:focus,
.cancel-button:active {
  background-color: #fff;
}

.cancel-button .MuiTouchRipple-root {
  display: none;
}

.prev {
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 15px 5px 26px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #6e8496;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  position: relative;
}

.prev::before {
  content: "\6e";
  font-family: "dashboard-icons";
  font-size: 9px;
  color: #b8bfc5;
  position: absolute;
  top: 9px;
  left: 8px;
  bottom: 0;
}

.next {
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 26px 5px 15px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #6e8496;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  position: relative;
}

.next::before {
  content: "\6f";
  font-family: "dashboard-icons";
  font-size: 9px;
  color: #b8bfc5;
  position: absolute;
  top: 9px;
  right: 8px;
  bottom: 0;
}

.MuiIconButton-root:hover {
  background-color: transparent;
}

.MuiButton-root.Mui-disabled {
  color: #fff;
  opacity: 0.5;
}

/*Filters Button and icon*/

.filters-wrap .white-button {
  padding: 7px 15px;
  background-color: #fff;
  border: 1px solid #dbe2e9;
  border-radius: 90px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #2b3f57;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  box-shadow: none;
  text-decoration: none;
  margin-right: 10px;
  display: inline-block;
  line-height: 20px;
}

.filters-wrap .white-button:hover,
.filters-wrap .white-button:focus,
.filters-wrap .white-button:active,
.filters-wrap .white-button.active {
  background-color: #fff;
  border: 1px solid #dbe2e9;
  color: #125a9c;
}

.filters-wrap .white-button span i {
  color: #2b3f57;
  font-size: 16px;
  padding: 2px 10px 0px 0px;
}

.filters-wrap .white-button span i:hover {
  color: #125a9c;
}

.white-butn {
  background-color: #fff;
  border: 1px solid #dbe2e9;
  border-radius: 90px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #2b3f57;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  box-shadow: none;
  text-decoration: none;
  padding: 9px 20px;
  margin: 0px 0px 8px 10px;
  display: block;
}

.white-butn:hover,
.white-butn:focus,
.white-butn:active,
.white-butn.active {
  background-color: #fff;
  border: 1px solid #dbe2e9;
  color: #125a9c;
  box-shadow: none;
}

.white-butn .MuiTouchRipple-root {
  display: none;
}

.white-butn span i {
  color: #2b3f57;
  font-size: 10px;
  padding: 2px 0px 0px 0px;
}

.white-butn span i:hover {
  color: #125a9c;
}

.reset span i {
  font-family: "dashboard-icons";
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 6px;
  padding: 0px;
}

.reset:hover {
  background-color: transparent;
}

.delete {
  margin: 0 5px;
}

.delete span i {
  color: #6e8496;
  font-size: 18px;
}

.active-inactive label,
.active-inactive label.Mui-focused {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  position: absolute;
  left: 44px;
  top: 11px;
  z-index: 1;
}

.active-inactive .MuiInput-formControl {
  margin-top: 0px;
}

.active-inactive .MuiInput-formControl:hover,
.active-inactive .MuiInput-formControl:focus,
.active-inactive .MuiInput-formControl:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none;
  border-radius: 90px;
}

.active-inactive .MuiInput-formControl::before {
  border-bottom: none;
}

.active-inactive .MuiSelect-select {
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #2b3f57;
  font-style: normal;
  line-height: 15px;
  border: 1px solid #e0e6ee;
  padding: 10px 40px 10px 90px;
  height: auto;
  background-color: #fff;
  border-radius: 90px;
  transition: all 0.3s;
  appearance: none;
  outline: none;
}

.active-inactive .MuiSelect-select:after {
  content: "\41";
  font-family: "dashboard-icons";
  font-size: 10px;
  color: #727a81;
  position: absolute;
  top: 12px;
  right: 16px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}

.active-inactive svg {
  display: none;
}

.active-inactive i {
  color: #6e8496;
  position: absolute;
  left: 20px;
  top: 12px;
  z-index: 1;
}

.select-box {
  width: 100%;
}

.select-box span {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  padding-left: 8px;
  padding-bottom: 4px;
}

.select-box .MuiSelect-select {
  padding: 10px 40px 10px 20px;
}

.select-box .MuiSelect-select em {
  font-style: normal;
}

.select-box label,
.select-box label.Mui-focused {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  position: absolute;
  left: 15px;
  top: -18px;
  z-index: 1;
}

.select-box .MuiInput-formControl {
  margin-top: 0px;
}

.select-box .MuiInput-formControl:hover,
.select-box .MuiInput-formControl:focus,
.select-box .MuiInput-formControl:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none;
  border-radius: 90px;
}

.select-box .MuiInput-formControl::before {
  border-bottom: none;
}

.select-box .MuiSelect-select {
  width: 100%;
  min-width: 80px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #2b3f57;
  font-style: normal;
  line-height: 15px;
  border: 1px solid #e0e6ee;
  padding: 10px 40px 10px 90px;
  height: auto;
  background-color: #fff;
  border-radius: 90px;
  transition: all 0.3s;
  appearance: none;
  outline: none;
}

.select-box .MuiSelect-select:after {
  content: "\41";
  font-family: "dashboard-icons";
  font-size: 10px;
  color: #727a81;
  position: absolute;
  top: 12px;
  right: 16px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}

.select-box .MuiSelect-select {
  padding: 10px 40px 10px 20px;
}

.select-box .MuiSelect-select em {
  font-style: normal;
}

.select-box svg {
  display: none;
}

.select-box i {
  color: #6e8496;
  position: absolute;
  left: 20px;
  top: 12px;
  z-index: 1;
}

.MuiMenu-paper {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(226, 231, 237);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.02);
}

.MuiMenu-paper ul li {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #4c6173;
  line-height: 15px;
}

.MuiMenu-paper ul li.Mui-selected,
.MuiMenu-paper ul li.Mui-selected:hover {
  background-color: #eef1f5;
}

.save-draft-buttton {
  background-color: #fff;
  border: 1px solid #4122d8;
  border-radius: 90px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #4122d8;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  box-shadow: none;
  text-decoration: none;
  padding: 9px 20px;
  margin-bottom: 8px;
  display: block;
  padding: 9px 20px;
  margin-bottom: 8px;
  display: block;
}

.save-draft-buttton:hover,
.save-draft-buttton:focus,
.save-draft-buttton:active,
.save-draft-buttton.active {
  background-color: #fff;
  border: 1px solid #4122d8;
  color: #4122d8;
  box-shadow: none;
}

/*Select Search Box*/

.select-search-box {
  width: 100%;
}

.select-search-box .MuiInputBase-root {
  width: 100%;
  min-width: 80px;
  /* max-width: 180px; */
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #2b3f57;
  font-style: normal;
  line-height: 15px;
  border: 1px solid #e0e6ee;
  padding: 10px 50px 10px 20px;
  height: auto;
  background-color: #fff;
  border-radius: 90px;
  transition: all 0.3s;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin: 0px;
}

.content-wrapper-inner
  .select-search-box
  .MuiFormControl-root
  .MuiInputBase-root
  input {
  padding: 0px;
  width: auto;
  min-width: auto;
}

/* .content-wrapper-inner .select-search-box .MuiInputBase-root fieldset {
  border: 0px solid #e0e6ee;
  top: 0px;
} */

.select-search-box label {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  z-index: 1;
  transform: translate(18px, -16px) scale(0.9);
}

.select-search-box label.Mui-focused {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  z-index: 1;
}

.select-search-box .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -12px) scale(0.9);
  padding-top: 4px;
}

.select-search-box .MuiAutocomplete-popupIndicator {
  width: 20px;
  height: 20px;
  position: relative;
  padding: 0px 12px 0px 0px;
  margin-right: 5px;
}

.MuiOutlinedInput-adornedEnd {
  outline: none;
}

.select-search-box .MuiInput-underline:before {
  border-bottom: 0px;
}

.select-search-box .MuiAutocomplete-popupIndicator:after,
.select-search-box .MuiAutocomplete-popupIndicatorOpen:after {
  content: "\41";
  font-family: "dashboard-icons";
  font-size: 11px;
  color: #727a81;
  position: absolute;
  top: 6px;
  right: 10px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}

.select-search-box .MuiAutocomplete-popupIndicator svg {
  display: none;
}

/*Filter Date*/

.date-filter {
  position: relative;
}

.date-filter .MuiInputBase-root {
  width: 100%;
  min-width: 80px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #2b3f57;
  font-style: normal;
  line-height: 15px;
  border: 1px solid #e0e6ee;
  padding: 10px 20px 10px 20px;
  height: auto;
  background-color: #fff;
  border-radius: 90px;
  transition: all 0.3s;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin-top: 0px;
}

.date-filter .MuiInputBase-root input {
  margin: 0px;
  padding: 0px 20px 0px 0px;
  appearance: unset;
}

.date-filter
  .MuiInputBase-root
  input[type="date"]::-webkit-calendar-picker-indicator {
  color: #727a81;
  opacity: 0;
  cursor: pointer;
  background-color: #ff6337;
  z-index: 1;
  position: absolute;
  top: 12px;
  right: 16px;
}

.date-filter .MuiInputBase-root input::after {
  content: "\72";
  font-family: "dashboard-icons";
  font-size: 14px;
  color: #697f92;
  position: absolute;
  top: 12px;
  right: 15px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}

.date-filter .MuiInputBase-root::after,
.date-filter .MuiInputBase-root::before {
  border-bottom: none;
}

.date-filter label,
.date-filter label.Mui-focused {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  position: absolute;
  left: 14px;
  top: -38px;
  z-index: 1;
}

.date-filter .MuiInputLabel-shrink {
  transform: translate(0px, 24px) scale(1);
}

/*Filter checkbox*/

.checkbox-label {
  padding: 8px 16px;
  background-color: #fff;
  border: 1px solid #dbe2e9;
  border-radius: 90px;
  box-shadow: none;
  text-decoration: none;
  margin: 0px 0px 10px;
  width: 100%;
}

.checkbox-label.active {
  background-color: #1ad3bf;
  border: 1px solid #57c8bc;
  border-radius: 90px;
  box-shadow: none;
}

.checkbox-label span.MuiTypography-root {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  padding-left: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.checkbox-label.active span {
  color: #ffffff;
}

.checkbox-label .MuiIconButton-label span {
  border: none;
  background-color: #fff;
  box-shadow: none;
  width: 0px;
  height: 0px;
}

.checkbox-label .MuiIconButton-label span::before {
  background-image: none;
  content: "\4f";
  font-family: "dashboard-icons";
  font-size: 12px;
  color: #6e8496;
  position: absolute;
  top: -8px;
  left: -8px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
  width: 0px;
  height: 0px;
}

.checkbox-label.active .Mui-checked .MuiIconButton-label span::before {
  color: #fff;
}

/*Search Bar*/

.filter-input {
  position: relative;
}

.filter-input label {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  top: -22px;
}

.filter-input .MuiInput-formControl {
  margin-top: 0px;
}

.filter-input .MuiInput-formControl:hover,
.filter-input .MuiInput-formControl:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none;
}

.filter-input .MuiInput-formControl::before {
  border-bottom: none;
}

.filter-input .MuiInput-formControl input {
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  color: #2b3f57;
  line-height: 15px;
  border: 1px solid #e0e6ee;
  padding: 10px 15px 10px 40px;
  height: auto;
  background-color: #fff;
  border-radius: 90px;
  transition: all 0.3s;
  appearance: none;
  outline: none;
}

.filter-input label {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  padding-left: 15px;
  position: absolute;
  top: -8px;
}

.filter-input i {
  position: absolute;
  top: 12px;
  left: 18px;
  color: #727a81;
  font-size: 14px;
  z-index: 1;
}

.search-box {
  position: relative;
}

.search-box label {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  top: -22px;
}

.search-box .MuiInput-formControl {
  margin-top: 0px;
  position: relative;
}

.search-box .MuiInput-formControl:hover,
.search-box .MuiInput-formControl:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none;
}

.search-box .MuiInput-formControl::before {
  border-bottom: none;
}

.search-box .MuiInput-formControl input {
  width: 45px;
  font-size: 13px;
  font-weight: 500;
  color: #2b3f57;
  line-height: 15px;
  border: 1px solid #e0e6ee;
  padding: 10px 15px 10px 35px;
  height: auto;
  background-color: #fff;
  border-radius: 90px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-box .MuiInput-formControl::before {
  content: "\34";
  font-family: "dashboard-icons";
  font-size: 16px;
  color: #727a81;
  position: absolute;
  top: 10px;
  left: 11px;
  bottom: 0;
  vertical-align: middle;
  pointer-events: none;
  z-index: 9;
}

.search-box .MuiInput-formControl input:focus {
  width: 140px;
  padding: 10px 15px 10px 40px;
  color: #2b3f57;
}

.search-box i {
  position: absolute;
  top: 12px;
  left: 18px;
  color: #727a81;
  font-size: 14px;
  z-index: 1;
}

/*Popup*/

.MuiBackdrop-root {
  background-color: rgb(6, 2, 25, 0.8);
}

.slide-right .MuiDialog-paperWidthSm {
  max-width: 560px;
}

.slide-right .MuiDialog-paper {
  position: fixed;
  right: 0;
  top: 0;
  padding: 15px 40px 40px 40px;
  margin: 0px;
  width: 560px;
  height: 100vh;
  max-height: calc(100% - 0px);
  border-radius: 0;
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
  z-index: 9;
}

.dismiss {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}

/*Form Fields*/

.form-wrap h2 {
  font-size: 18px !important;
  font-weight: 600;
  color: #2b3f57;
  padding-bottom: 8px;
  padding-left: 12px;
}

.form-wrap .MuiDialogTitle-root {
  padding: 0px;
}

.form-wrap h2.form-wrap .MuiInputLabel-formControl {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #344750;
  text-align: left;
  padding: 0px 0px 20px;
  margin: 0px;
}

.form-wrap h3 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #2b3f57;
  text-align: left;
  padding: 0px 0px 15px;
  margin: 0px;
}

.form-wrap .MuiInputLabel-formControl {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #6e8496;
  text-align: left;
}

.form-wrap .MuiInputLabel-formControl span {
  color: red;
}

.form-wrap .MuiDialogContent-root {
  padding: 0px;
  overflow: inherit;
}

.form-wrap .MuiFormControl-root {
  min-width: auto;
  margin-bottom: 10px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.form-wrap .MuiInputBase-root {
  padding: 15px 24px 15px 24px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  border-radius: 90px;
}

.form-wrap .MuiAutocomplete-inputRoot span i.dashboardicon-down {
  font-size: 11px;
  color: #8496a6;
  padding: 6px 10px 0px 10px;
  border: none;
  margin: 0px;
}

.form-wrap .MuiAutocomplete-inputRoot span i.dashboardicon-close {
  font-size: 11px;
  color: #8496a6;
  padding: 6px 0px 0px 0px;
  border: none;
  margin: 0px;
}

.form-wrap .MuiAutocomplete-inputRoot span svg path {
  appearance: none;
}

.form-wrap .MuiInputLabel-formControl {
  transform: translate(24px, 18px) scale(1);
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(22px, -10px) scale(0.9);
  padding-top: 4px;
}

.form-wrap .masked-phone-number .MuiInput-input,
.form-wrap .MuiInputBase-root input,
.form-wrap .MuiInputBase-root textarea {
  width: 100%;
  min-width: auto;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #0d3f67;
  padding: 0px !important;
  background-color: #fff;
}

.form-wrap .MuiInputBase-root textarea {
  resize: none;
  height: 55px;
}

.form-wrap .MuiInputBase-root fieldset span {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: #6e8496;
  text-align: left;
}

.form-wrap .MuiInputBase-root fieldset {
  border: 1px solid #e2e8ef;
  outline: none;
  box-shadow: none;
}

.form-wrap .MuiInputBase-root fieldset:hover {
  border: 1px solid #2c29e7;
  position: relative;
  z-index: 999;
}

.form-wrap .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #e2e8ef;
}

.form-wrap .masked-phone-number .MuiInput-input,
.form-wrap .MuiInputBase-root input,
.form-wrap .MuiInputBase-root textarea {
  width: 100%;
  min-width: auto;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #0d3f67;
  padding: 0px !important;
  background-color: #fff !important;
}

.form-wrap .masked-phone-number {
  position: relative;
  /* top: -7px; */
}

.form-wrap .masked-phone-number::after {
  content: "";
  clear: both;
  display: block;
  height: auto;
}

.form-wrap .masked-phone-number .MuiInputBase-root {
  margin-top: 0px !important;
  border: 1px solid #e2e8ef;
  outline: none;
  box-shadow: none;
  border-radius: 90px;
}

.form-wrap .masked-phone-number .MuiInputBase-root:before {
  border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}

.form-wrap .masked-phone-number .MuiInputLabel-formControl {
  transform: translate(16px, -8px) scale(1) !important;
  z-index: 1;
  background-color: #fff;
  display: inline-block;
  padding: 0px 10px 0px 3px;
}

.MuiInput-underline:after {
  display: none;
}

/*select box */

.form-select-box .MuiFormControl-root {
  width: 100%;
}

.form-select-box .MuiSelect-root {
  margin: 0px;
  padding: 0px;
  min-height: auto;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #0d3f67;
  font-style: normal;
}

.form-select-box .MuiInputBase-root::after {
  content: "\41";
  font-family: "dashboard-icons";
  font-size: 10px;
  color: #0d3f67;
  position: absolute;
  top: 16px;
  right: 24px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}

.form-select-box .MuiSelect-root em {
  font-style: normal;
}

.form-select-box .MuiInputBase-root {
  line-height: 18px;
  padding: 15px 24px 15px 24px;
}

.form-select-box .MuiInputBase-root svg {
  display: none;
}

.form-wrap .MuiAutocomplete-inputRoot span svg:after,
.form-select-box .MuiInputBase-root span svg:after {
  content: "\46";
  font-family: "dashboard-icons";
  font-size: 10px;
  color: #727a81;
  position: absolute;
  top: 17px;
  right: 16px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.form-select-box .MuiInputBase-root svg {
  display: none;
}

.form-select-box .MuiInputBase-root::after {
  content: "\41";
  font-family: "dashboard-icons";
  font-size: 11px;
  color: #727a81;
  position: absolute;
  top: 16px;
  right: 24px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}

.form-select-box .MuiInputLabel-formControl {
  transform: translate(16px, -8px) scale(1);
  background-color: #fff;
  padding: 0px 8px;
  z-index: 9;
  font-size: 12px;
}

/*choose-file*/

.form-wrap .choose-file {
  width: 100%;
  min-height: 85px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #06a3f7;
  text-align: center;
  background-color: #fff;
  border: 2px dashed #e2e8ef;
  border-radius: 4px;
  box-shadow: none;
}

.form-wrap .choose-file:hover,
.form-wrap .choose-file:focus,
.form-wrapm .choose-file:active {
  background-color: #fff;
  border: 2px dashed #e2e8ef;
}

.form-wrap .choose-file span span {
  width: auto;
  color: #7b8994;
  float: left;
  margin-right: 3px;
}

/*Form Checkbox*/

.form-checkbox {
  margin-bottom: 15px;
  margin-left: 5px;
}

.form-checkbox span {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #8e908f;
  text-align: left;
}

.form-checkbox .MuiIconButton-label span {
  width: 18px;
  height: 18px;
  background-image: none;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #acacac;
  border-radius: 3px;
}

.form-checkbox .Mui-checked .MuiIconButton-label span {
  width: 18px;
  height: 18px;
  background-image: none;
  background-color: #21c4d3;
  box-shadow: none;
  border: 0px solid #19c3b1;
  border-radius: 3px;
  color: #344750;
}

.form-checkbox .Mui-checked .MuiIconButton-label span::before {
  content: "\4f";
  color: #fff;
  font-size: 9px;
  font-family: "dashboard-icons" !important;
  background-image: none;
  text-align: center;
  line-height: 18px;
  padding-left: 3px;
  /* height: 15px;
  background-position: 0px -1px; */
}

/*Placeholder*/

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #727a81;
  font-size: 13px;
  font-weight: 400;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #727a81;
  font-size: 13px;
  font-weight: 400;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #727a81;
  font-size: 13px;
  font-weight: 400;
}

/*Login Soical Button */

.google-button {
  background: url("./images/google-icon.png");
  background-repeat: no-repeat;
  background-position: 12px 10px;
  padding: 9px 20px 9px 40px;
  background-color: #fff;
  border: 1px solid #dbe2e9;
  border-radius: 90px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #2b3f57;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  box-shadow: none;
  text-decoration: none;
  margin: 0px;
  float: right;
}

.google-button:hover,
.google-button:focus,
.google-button:active,
.google-button.active {
  background-color: #fff;
  border: 1px solid #dbe2e9;
  color: #125a9c;
  box-shadow: none;
}

.facebook-button {
  background: url("./images/fb-icon.png");
  background-repeat: no-repeat;
  background-position: 18px 11px;
  padding: 9px 20px 9px 40px;
  background-color: #fff;
  border: 1px solid #dbe2e9;
  border-radius: 90px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #2b3f57;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  box-shadow: none;
  text-decoration: none;
  margin: 0px;
  float: right;
}

.facebook-button:hover,
.facebook-button:focus,
.facebook-button:active,
.facebook-button.active {
  background-color: #fff;
  border: 1px solid #dbe2e9;
  color: #125a9c;
  box-shadow: none;
}

/* .menu-drawer-close .logo {
  display: none;
}

.menu-drawer-close .small-logo {
  display: block;
} */

.logo,
.desktop-top-bar {
  display: inline-flex;
}

.mb-logo,
.mobile-top-bar {
  display: none;
}

.assign-select-box label,
.select-box label.Mui-focused {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #6e8496;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.5px;
  position: absolute;
  left: 15px;
  top: -18px;
  z-index: 1;
}

.assign-select-box .MuiInput-formControl {
  margin-top: 0px;
}

.assign-select-box .MuiInput-formControl:hover,
.assign-select-box .MuiInput-formControl:focus,
.assign-select-box .MuiInput-formControl:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none;
  border-radius: 90px;
}

.assign-select-box .MuiInput-formControl::before {
  border-bottom: none;
}

.assign-select-box .MuiSelect-select {
  width: 100%;
  min-width: 80px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #2b3f57;
  font-style: normal;
  line-height: 15px;
  border: 1px solid #f5f5fd;
  height: auto;
  background-color: #f5f5fd;
  border-radius: 90px;
  transition: all 0.3s;
  appearance: none;
  outline: none;
  margin-top: 3px;
}

.assign-select-box .MuiInputBase-root:before {
  content: "";
  display: block;
  clear: both;
  height: auto;
  border: none;
}

.assign-select-box .MuiSelect-select:after {
  content: "\41";
  font-family: "dashboard-icons";
  font-size: 10px;
  color: #727a81;
  position: absolute;
  top: 10px;
  right: 12px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}

.assign-select-box .MuiSelect-select {
  padding: 5px 14px 5px 12px;
}

.assign-select-box .MuiSelect-select em {
  font-style: normal;
}

.assign-select-box svg {
  display: none;
}

.assign-select-box i {
  color: #6e8496;
  position: absolute;
  left: 20px;
  top: 12px;
  z-index: 1;
}

.filter-area h2 {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 22px !important;
  font-weight: 500;
  color: #2b3f57;
  text-align: left;
  margin: 12px 8px;
}

/* masked input start */

.masked-phone-number {
  position: relative;
}

.masked-phone-number label {
  z-index: 1;
  font-size: 13px;
  font-weight: 400;
  color: #6e8496;
  text-align: left;
  position: absolute;
  left: 22px;
  top: -8px;
  background-color: #fff;
  padding: 0px 8px;
}

.masked-phone-number label span {
  color: #ff6337;
}

.masked-phone-number input {
  width: 100%;
  min-width: auto;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #0d3f67;
  padding: 0px;
  background-color: #fff;
  border: 1px solid #e2e8ef;
  padding: 15px 24px;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  border-radius: 90px;
  outline: none;
}

/* masked input ends */
@media (max-width: 991px) {
  .makeStyles-sectionDesktop-15 {
    display: block;
  }
}
@media only screen and (max-width: 600px) and (min-width: 320px) {
  .appbar-main header {
    padding: 0px 30px;
  }
  .makeStyles-sectionDesktop-15,
  .PrivateHiddenCss-xsDown-20 {
    display: block !important;
  }
  .menu-drawer-close:hover {
    width: 0px !important;
  }
  .menu-drawer-open {
    width: 250px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    transition: ease-out;
  }
  .menu-drawer-close {
    width: 0px;
  }
  .mui-fixed {
    width: calc(100% - 0px);
  }
  .logo,
  .desktop-top-bar {
    display: none;
  }
  .mobile-top-bar {
    display: inline-flex;
    padding: 0px;
  }
  .mb-logo {
    width: 100%;
    display: block;
    float: left;
  }
  .white-bg {
    padding: 10px;
  }
  .contenter-main {
    width: calc(100% - 0px);
  }
  .content-wrapper-inner {
    width: calc(100% - 0px);
    padding: 40px 20px 20px 20px;
    transition: 0.1s;
  }
  .appbar-main .appbar-shift {
    margin-left: 0px;
    width: calc(100% - 0px);
    transition: 0.5s;
    padding: 0px 10px;
  }
  .mb-appbar-main header {
    width: 100%;
    background-color: white;
    padding: 10px 10px;
  }
  .mb-appbar-main:after {
    content: "";
    display: block;
    clear: both;
    height: auto;
  }
  .drawer-open .content-wrapper-inner,
  .drawer-open .content-wrapper {
    padding: 30px 10px 10px 10px;
  }
  .slide-right .MuiDialog-paper {
    width: 100%;
    padding: 15px 20px 20px;
  }
  .form-wrap .MuiFormControl-root {
    width: 100%;
    margin-bottom: 0px;
  }
  .form-checkbox {
    margin-bottom: 5px;
    margin-left: 5px;
  }
  .MuiMenuItem-root {
    min-height: 36px;
  }
  .filter-input {
    width: 100%;
  }
  .filter-input .MuiInput-formControl {
    width: 100%;
  }
  .mb-menu-close {
    position: fixed;
    right: 14px;
    top: 14px;
    width: auto;
  }
  .mb-menu-close i {
    color: #fff;
    font-size: 14px;
  }
  .username-tx {
    padding: 10px 10px 10px;
  }
  .menu-drawer-open a.mb-profile-pic {
    border-bottom: 1px solid #d9d9f1;
    border-radius: 0px;
  }
  .menu-drawer-open a .MuiIconButton-label {
    justify-content: flex-start;
    padding-left: 10px;
  }
  .sidemenu-basic {
    padding-top: 70px;
  }
  .google-button {
    background-image: none;
    width: 100%;
    display: block;
  }
  .google-button span {
    position: relative;
    width: 100%;
    display: block;
  }
  .google-button span:before {
    content: "";
    display: block;
    clear: both;
    background: url("./images/google-icon.png");
    background-repeat: no-repeat;
    background-position: 0px 0px;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    padding-left: 118px;
  }
  .facebook-button {
    background-image: none;
    width: 100%;
    display: block;
  }
  .facebook-button span {
    position: relative;
    width: 100%;
    display: block;
  }
  .facebook-button span:before {
    content: "";
    display: block;
    clear: both;
    background: url("./images/fb-icon.png");
    background-repeat: no-repeat;
    background-position: 0px 0px;
    width: 9px;
    height: 19px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    padding-left: 118px;
  }
  .mb-form-wrap .mb-form-button > div {
    margin: 0px;
  }
  .mb-form-wrap .mb-form-button button {
    width: 100%;
  }
  /*filter Area*/
  .filter-area .MuiGrid-item {
    width: 100%;
  }
  .filter-area .MuiGrid-item .MuiInputAdornment-root {
    margin: 0px;
  }
  .select-box {
    width: 100%;
    margin-top: 18px;
  }
  .date-filter .MuiFormControl-root:first {
    margin-top: 18px;
  }
  .date-filter .MuiFormControl-root {
    width: 100%;
    margin-top: 18px;
  }
  .add-butn {
    width: 98%;
    margin: 12px auto 0px;
  }
  .select-search-box .MuiInputBase-root {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .reset {
    width: 100%;
    text-align: right;
    margin-bottom: 18px;
  }
  .reset {
    line-height: 28px;
    margin: 0px;
    padding: 15px 15px 0px;
  }
  .reset span {
    display: block;
    padding: 0px;
    margin: 0px;
  }
  .reset span i {
    position: relative;
    float: right;
    top: 0px;
  }
  .mb-reset {
    padding: 0px 15px 0px;
  }
  .select-box,
  .select-search-box {
    margin-top: 15px;
  }
  .select-search-box .MuiInputBase-root {
    max-width: 100%;
    min-width: 100%;
  }
  .filter_input {
    width: 100%;
  }
  .add-butn {
    width: 96%;
    margin-top: 15px;
  }
  .search-box,
  .active-inactive {
    width: 100%;
  }
  .search-box .MuiInput-formControl input {
    width: 100%;
  }
}

.add-butn {
  margin-right: 0.5rem;
}

span.SerachBOX input {
  padding: 5px;
  min-height: 35px;
  border-radius: 0.25rem;
  margin-right: 1rem;
  border: 1px solid #ddd;
  min-width: 204px;
}

.Dis_closer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 0.8rem;
  margin-bottom: 1rem;
}

.iconsBX {
  min-width: 50px;
}
.iconsBX i {
  font-size: 3rem;
  color: #8a8a91;
}

.Dis_closer:hover {
  background: #112268;
  color: #fff;
  cursor: pointer;
}
.Dis_closer:hover .iconsBX i {
  color: #fff;
}

.Dis_closerg label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 0.8rem;
  margin-bottom: 1rem;
}
.Dis_closerg label:hover {
  background: #0d660e;
  color: #fff;
  cursor: pointer;
}
.Dis_closerg:hover .iconsBX i {
  color: #fff;
}
.Name-text .MuiFormControl-root {
  width: 100%;
  margin-bottom: 1rem;
}
.Name-text {
  margin-bottom: 2rem;
}

.Name-text p {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.main_contain {
  background: #fff;
  width: 100%;
  margin: 1.5rem 0.6rem;
  padding: 1rem 1.5rem;
}
.dicsr_tile p {
  font-size: 16px;
  color: #677982;
  font-weight: 500;
  padding: 0.4rem 0rem 1.5rem 0rem;
}

.dicsr_tile {
  border-bottom: 1px solid #9f9f9f;
  margin-bottom: 1.5rem;
}

.Content_body p {
  font-size: 16px;
}

.Content_body {
  margin-bottom: 1rem;
}

.title-headr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title-headr i {
  font-size: 1.4rem;
}

.transh_box i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.transh_box i:nth-child(1) {
  color: #bb1111;
}

.transh_box i:nth-child(2) {
  color: #02810c;
}
.transh_box {
  display: flex;
  align-items: center;
}
.maincontent {
  background-color: #fff;
  padding: 0rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.sidemenu {
  background-color: #fff;
  padding: 0.6rem 2rem;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.sidemenu ul {
  list-style: none;
}
.w100 {
  width: 100%;
  margin-top: 1rem;
}
.menuitem a {
  color: #5c7c8b;
  padding: 0;
  font-size: 1.2rem;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}
.menuitem {
  border-bottom: 1px solid #bbc9cf;
  padding: 0.8rem 0rem;
}
.menuitem:last-child {
  border-bottom: 0px;
}
.ql-editor {
  height: 100vh !important;
}
.maindisc {
  background-color: aliceblue;
  /* padding: 2.5rem; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  border-radius: 5px;
  padding: 10px;
}
.discluser {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  /* padding: 0.8rem; */
}
.discluser:last-child {
  border-bottom: 0px;
}
.obs-link {
  color: #5c7c8b !important;
  padding: 0rem !important;
  font-size: 1rem !important;
  text-align: left !important;
  text-decoration: none;
  margin-bottom: 5px;
}
.obs-link :hover {
  text-decoration-line: underline;
  color: #007bff;
}
.cdflex {
  display: flex;
  align-items: center;
}
.marginremove {
  margin-bottom: 0rem !important;
}
.juriflex {
  display: flex;
  flex-direction: column;
}
.name-column {
  flex: 1 0;
  font-size: 18px;
  width: 350px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.fileicon {
  margin-right: 1rem;
}
.name-column {
  font-size: 16px;
  color: #4f4f4f;
}
.name-column i {
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}
.in-use-column {
  font-size: 18px;
  white-space: nowrap;
  color: #112268;
  margin-right: 10px;
}
.btn-jaleh.btn-danger {
  background: #ff7675;
  color: #fff;
}
.btn-jaleh.small {
  padding: 7px 15px;
  height: 35px;
  font-size: 14px;
}
.btn-jaleh.green {
  cursor: pointer;
  background-color: #3fb99a;
  color: #fff;
}
.btn-copy {
  font-size: 18px !important;
  color: #5c7c8b !important;
  cursor: pointer;
}
.btn-delete {
  font-size: 18px !important;
  color: #ffa9a8 !important;
  cursor: pointer;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-wrapper {
  padding-left: 10px;
}
/* .actions-column {
    flex: 1 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
} */
#fileLabel {
  display: flex;
  align-items: center;
}
.active-star {
  color: #ffbb02 !important;
}
.closebtn {
  position: absolute;
  right: 18px;
  top: 16px;
  cursor: pointer;
  color: rgb(215, 67, 67);
}
.title-headr a {
  color: #112268;
  cursor: pointer;
}
/* .propertyimage{
  height: 180px;
  width: 240px;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 64px;
  color: #ddd;
} */

.propertyimage {
  position: relative;
  height: 300px;
  width: 400px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.border-sec {
  width: 100%;
  position: absolute;
  /* border: 1px solid gray; */
  /* padding: 23px; */
  height: 50px;
  bottom: 0;
  background: rgba(50, 50, 50, 0.7);
}
.border-sec i {
  padding: 0.9rem;
  margin-right: -60px;
}

.propertydesc {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

Link:hover {
  box-shadow: inset 100px 0 0 0 #54b3d6;
  color: white;
}
.fa-building {
  font-weight: 900;
  width: 30px !important;
  height: 40px;
  font-size: 6.5rem;
}

/* CSS for 13-05-2023 */

.obs-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #5c7c8b;
  font-size: 24px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.obs-card-header .actions {
  margin-left: 50px;
  font-size: 16px;
}
.obs-link {
  color: #5c7c8b;
  padding: 0;
  font-size: inherit;
  text-align: left;
}
.obs-card-header .actions i {
  font-size: 18px;
  font-weight: 400;
}

.obs-form-group label {
  text-transform: uppercase;
  color: #63727d;
  font-size: 14px;
  margin-bottom: 5px;
}
.nodata {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.nodata h3 {
  padding: 20px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}
.nodata p {
  padding: 35px;
  text-align: center;
}

.btn-green {
  display: block;
  padding: 20px 30px;
  background-color: #3fb99a;

  border-radius: 100px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  border: none;
  transition: 0.5s;
  cursor: pointer;
}
.btn-green:hover {
  background-color: #161c6a;
}
.m-auto {
  margin: auto !important;
}
.row-custom {
  display: flex;
  justify-content: space-evenly;
}
.col-4-custom {
  width: 33.33%;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding: 20px 0 40px;
}
.col-4-custom .content-col p {
  margin-bottom: 20px;
  color: #63727d;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
}
.col-4-custom .content-col h3 {
  margin-bottom: 0;
  color: #ff7675;
  font-size: 1.5em;
  text-align: center;
}

.obs-list-header {
  padding: 30px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chetu-test {
  margin-top: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px 20px;
}

.innerchetu-test {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.innerchetu-test .insidesec-test p {
  color: #5c7c8b;
  font-size: 24px;
}

.insidesec-test ul li::before {
  content: "•";
  margin-right: 5px;
}

.innerchetu-test .insidesec-test1 p {
  font-size: 20px;
  color: #5c7c8b;
}

.insidesec-test2 {
  display: flex;
  align-items: center;
}

.insidesec-test1 p span {
  font-size: 18px;
}
/* .obs-toggle.off {
  color: #ff7675;
}
.obs-toggle {
  cursor: pointer;
  user-select: none;
}
.property-show .property-available {
  font-size: 18px;
}
.obs-toggle i+span {
  margin-left: 5px;
}
.obs-toggle.on {
  color: #3fb99a;
}
.property-show .property-available .obs-toggle.on {
  color: #3fb99a;
}
.fa-toggle-on:before {
  content: "\f205";
} */

.property-img {
  position: relative;
  height: 200px;
  width: 300px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* .discl-user1{
  padding: 15px;
} */

.property-img {
  position: relative;
  height: 200px;
  width: 300px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.property-sec1 {
  width: 100%;
  position: absolute;
  height: 40px;
  bottom: 0;
  background: #ff7675;
}
.property-sec2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.popup-outer {
  background-color: red;
}

.input-design {
  width: 100%;
  padding: 10px;
}
.input-btn {
  background-color: #007bff;
  color: white;
  border: none;
  margin-left: 15px;
  padding: 12px 25px;
}
.main-input-sec {
  display: flex;
}
.uplod-opecity {
  opacity: 0;
  cursor: pointer;
}

/* New CSS for 30 11 2023 Starts */

/* SIdebar and header section */
.mui-fixed button,
.mui-fixed button:hover {
  background-color: #cecece !important;
  color: #232323 !important;
}
.username-tx {
  font-family: "poppins" !important;
  color: #232323 !important;
}

.menu-drawer-open {
  background-color: #fafafa;
}

.sidemenu-basic a {
  margin-top: 5px;
}

.sidemenu-basic a:hover,
.sidemenu-basic a:focus,
.sidemenu-basic a.Mui-selected,
.sidemenu-basic a.Mui-selected:focus {
  color: #232323;
  background-color: #cecece;
  margin: 5px auto 0 auto;
}

.MuiListItem-button.Mui-selected.active span {
  font-size: 14px;
}
.sidemenu-basic a.Mui-selected,
.sidemenu-basic a.Mui-selected:hover {
  color: #232323;
  background-color: #cecece;
  margin: 5px auto 0 auto;
}
.logout-menu a:hover,
.logout-menu a.Mui-selected {
  color: #232323;
  background-color: #cecece;
  margin: 0 auto;
}
.logout-menu a span,
.sidemenu-basic a span {
  font-size: 14px;
}
.logo {
  background-color: #fafafa;
}

/*tabs button */
ul.sub-menu-title a.foractive {
  background-image: none;
  box-shadow: none;
  padding: 5px 25px 9px 25px;
  border-radius: 0;
  color: #0f5f8b !important;
  border-bottom: 2px solid #0f5f8b;
}

/*tabs cards */

.maindisc .table-list table thead tr th span {
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  color: #232323;
}
.maindisc .table-list table thead tr th {
  background-color: #d3d3d357;
}
.tabs-section .MuiButton-contained {
  background-color: #ffffff;
  color: #232323;
  border: 1px solid #232323;
  box-shadow: 0 0 4px #232323 !important;
}
.tabs-section .MuiButton-contained:hover {
  background: #f0f0f0;
  color: #000 !important;
  box-shadow: none;
  border: 1px solid #000;
}
.tabs-section .table-list table tbody td button span.MuiButton-label {
  text-transform: capitalize;
}
.maindisc {
  background-color: white;
  box-shadow: 0 0 6px rgb(191 191 191);
  border-radius: 10px;
}
.tabs-section .table-list table tbody tr td:first-child,
.tabs-section .table-list table tbody tr td:last-child {
  border-radius: 0;
}
.p-23 {
  padding: 40px 20px !important;
  margin-top: 0 !important;
}

/* Submit Button */

.maindisc .form-wrap .MuiButton-contained {
  background-color: #009688;
  color: #fff;
}
.maindisc .form-wrap .MuiButton-contained:hover {
  background-color: #00695f;
  color: #fff;
}

/*Application Page starts */

.table-list table tbody tr td:first-child,
.table-list table tbody tr td:last-child {
  border-radius: 0 !important;
}
.table-list table tbody tr td {
  font-size: 14px;
  color: #232323;
}
.table-list table tbody tr:hover td {
  background-color: #f0f0f06b;
}

/*Application Page Ends */

/*library  Page Starts */

.nav_list {
  list-style-type: none;
  background: #fff;
  float: left;
  cursor: pointer;
  border-top: none;
  border-radius: 0;
}
.juriflex .btn {
  color: #232323 !important;
}
.actions-column i.fa-solid.fa-trash-can {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #ff5858;
  cursor: pointer;
}
.actions-column i.fa-solid.fa-trash-can:hover {
  color: #d84545;
}
.maindisc .discluser {
  padding: 0.5rem !important;
}

/*modal on page*/
div#customized-dialog-title {
  background-color: #f4f4f4;
}
.MuiDialogActions-root button.MuiButton-root,
.cdflex .MuiButton-contained {
  border: 1px solid #232323 !important;
  color: #232323 !important;
  padding: 5px 20px !important;
  background: #fff;
}
.MuiDialogActions-root button.MuiButton-root:hover,
.cdflex .MuiButton-contained:hover {
  background-color: #cecece !important;
  color: #232323 !important;
}

/*library Page Ends */

/*Admin Page Start */

.table-list table tbody tr td a {
  color: #232323;
}
tr.MuiTableRow-root i {
  font-size: 1.3rem;
}
.juriflex .btn span {
  color: #232323;
}
.juriflex a.btn.btn-link.obs-link {
  margin: 0;
}
/*Admin Page Ends */

/* Please remove this below CSS if facing styling issue on adding new dropdown and or select */
.cdflex .MuiFormControl-root.form {
  left: -50%;
}

.cdflex .MuiInput-underline:before {
  border-bottom: none;
}
/* Please remove this Above CSS if facing styling issue on adding new dropdown and or Select */

.text-theme span {
  color: #232323;
}
/* New CSS for 30 11 2023 Ends */

/*CSS for 4 Dec 2023 Starts */
a.btn.presetBtn {
  border: 1px solid #232323 !important;
  color: #232323 !important;
  padding: 5px 20px !important;
  background: #fff;
  font-size: 14px;
  border-radius: 20px;
  text-decoration: none;
}

a.btn.presetBtn:hover {
  background-color: #cecece !important;
  color: #232323 !important;
}
.MuiMenu-paper .MuiMenu-list li div {
  display: flex;
  align-items: center;
}
.MuiMenu-paper .MuiMenu-list li i {
  font-size: 15px;
}
.MuiMenu-paper .MuiMenu-list li span {
  font-size: 14px;
}
/*CSS for 4 Dec 2023 Ends */
/* .Login_login-main__3illx {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 40px 20px;
  border-radius: 10px;
  width: 500px;
  height: calc(100vh - 500px);
}

.MuiContainer-root {
  height: 100%;
}

.makeStyles-paper-2 {
  height: 100%;
}

.Login_login-main__3illx .MuiContainer-root {
  background: none !important;
  box-shadow: unset !important;
}
.Login_login-main__3illx .form-wrap .MuiFormControl-root {
  margin-bottom: 40px !important;
} */
