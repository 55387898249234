/* Absolute Center Spinner */

.suspense-loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */

.suspense-loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: radial-gradient(rgba(107 72 255, .8), rgba(169 148 255, .8));
    background: -webkit-radial-gradient(rgb(107 72 255), rgb(169 148 255)); */
}

/* :not(:required) hides these rules from IE9 and below */

.suspense-loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.suspense-loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 2000ms infinite linear;
  -moz-animation: spinner 2000ms infinite linear;
  -ms-animation: spinner 2000ms infinite linear;
  -o-animation: spinner 2000ms infinite linear;
  animation: spinner 2000ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(107, 72, 255, 0.75) 1.5em 0 0 0,
    rgba(107, 72, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(107, 72, 255, 0.75) 0 1.5em 0 0,
    rgba(107, 72, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(107, 72, 255, 0.75) -1.5em 0 0 0,
    rgba(107, 72, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(107, 72, 255, 0.75) 0 -1.5em 0 0,
    rgba(107, 72, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(107, 72, 255, 0.75) 1.5em 0 0 0,
    rgba(107, 72, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(107, 72, 255, 0.75) 0 1.5em 0 0,
    rgba(107, 72, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(107, 72, 255, 0.75) -1.5em 0 0 0,
    rgba(107, 72, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(107, 72, 255, 0.75) 0 -1.5em 0 0,
    rgba(107, 72, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.signature-font {
  font-family: "Dancing Script" !important;
  border: 1px solid #e2e8ef;
  color: #3f50b5 !important;
  font-size: 40px !important;
}

.signature-note {
  font-size: 11px;
  font-style: italic;
}
.sub-menu-title {
  display: flex;
  justify-content: flex-start;
  padding: 15px;
  grid-gap: 40px;
  gap: 40px;
  margin: 15px 0px;
  width: -moz-fit-content;
  width: fit-content;
  list-style: none;
}

.sub-menu-title li a {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #484848;
  padding: 5px 25px 9px 25px;
  border-bottom: 2px solid rgb(137 137 137 / 13%);
}
.room_list_active {
  background: #2980b9 !important;
  color: white;
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.swal2-container {
  z-index: 999999 !important;
}

@media print {
  html,
  body {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    height: 100vh !important;
  }
}
