.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #777777;
}

.dropdown-content a i{
  margin-right:1rem;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropdown-content a i{
  color:#4a57a1;
}

.table-list table tbody tr td:last-child {
  text-align: left;
}



.MuiButton-contained {
    background-color: #ffffff;
    color: #4a57a1;
}






.dropdown-test{
  cursor: pointer;
}

.username-test::after{
  content: '';
  display: inline-block;
  border-width: 5px;
  border-color: #232323 transparent transparent transparent;
  border-style: solid;
  position: relative;
  top: 3px;
  left: 6px;
}