@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.static-page {
  height: 100%;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1.5px 2.598px 4px 0px rgba(0, 28, 61, 0.01);
  padding: 45px 40px 32px;
  margin: 40px 40px;
  position: relative;
}

.static-page h1, .static-page h2, .static-page h3, .static-page h4 {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.5px;
  padding: 0px 0px 0px;
  margin: 20px 0px 20px;
}

.static-page h1 {
  font-size: 18px;
  font-weight: 500;
  color: #344750;
}

.static-page h2 {
  font-size: 24px;
  font-weight: 600;
  color: #112268;
}

.static-page h3 {
  font-size: 24px;
  font-weight: 500;
  color: #112268;
}

.static-page h3 span {
  font-weight: 600;
  color: #21c4d3;
}

.static-page h4 {
  font-size: 14px;
  font-weight: 600;
  color: #344750;
}

.static-page p {
  font-size: 15px;
  color: #677982;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  /*margin: 0px 0px 0px 0px;*/
  margin: 0px 0px 20px;
  line-height: 18px;
}

.static-page p a {
  color: #112268;
}

.static-page ol {
  width: auto;
  padding: 0px;
  margin: 0px 0px 0px 30px;
}

.static-page ol li {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #2b3f57;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  margin: 0px 0px 0px 0px;
  padding: 0px !important;
  line-height: 30px;
}

.static-page ul {
  width: 100%;
  padding: 0px;
  margin: 15px 0px 30px 30px;
}

.static-page ul li {
  font-size: 14px;
  color: #2b3f57;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  margin: 0px 0px 0px 0px;
  padding: 0px !important;
  line-height: 30px;
}