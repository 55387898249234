.login-main {
  width: 350px;
  margin: 0 auto;
  position: absolute;
  transform: translateY(-50%);
  left: 50%;
  top: 50%;
  margin-left: -300px;
  text-align: center;
  height:calc(100vh - 500px) ;
  box-shadow: 0px 1px 2px 0px rgb(17 33 104 / 82%), 1px 2px 4px 0px rgb(17 33 104), 2px 4px 8px 0px rgb(255 255 255), 2px 4px 16px 0px rgb(46 59 122);
  padding: 40px 20px;
  border-radius: 10px;
  width: 500px;
  height: 500px;
}







.login-logo {
  padding-bottom: 20px;
}

.login-main h1 {
  font-size: 20px;
  padding-bottom: 30px;
}

.login-main :global .form-wrap .MuiFormControl-root {
  margin-bottom: 15px;
}

.login-with-social {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #e7ecf2;
  position: relative;
}

.login-with-social :global .MuiGrid-item {
  width: auto;
  float: left;
  display: contents;
}

.login-with-social::before {
  background-color: #fff;
  font-size: 14px;
  color: #7f878e;
  content: "or";
  display: block;
  clear: both;
  width: 20px;
  position: absolute;
  top: -12px;
  left: 50%;
  margin-left: -10px;
}

:global .MuiGrid-root :local .login-with-google {
  background-color: #4285f4;
  border: 1px solid #2d7df5;
  padding: 6px 15px 6px 40px;
  width: 170px;
  margin: 0 auto;
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  letter-spacing: .5px;
  border-radius: 90px;
}

:global .MuiGrid-root :local .login-with-google:hover, :global .MuiGrid-root :local .login-with-google:focus {
  background-color: #4285f4;
  border: 1px solid #2d7df5;
}

.login-with-google:before {
  background: url("./../../../images/google-icon.png");
  background-repeat: no-repeat;
  background-position: 5px 6px;
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 3px;
  top: 2px;
  background-color: #fff;
  padding: 15px;
  border-radius: 90px;
  text-align: center;
}

.login-with-facebook:before {
  background: url("./../../../images/fb-icon.png");
  background-repeat: no-repeat;
  background-position: 10px 6px;
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 3px;
  top: 3px;
  background-color: #fff;
  padding: 15px;
  border-radius: 90px;
  text-align: center;
}

.login-with-facebook {
  background-color: #415dae;
  border: 1px solid #415dae;
  padding: 7px 10px 8px 38px;
  width: 170px;
  margin: 0 auto;
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  letter-spacing: .5px;
  border-radius: 90px;
}

.login-with-facebook span {
  font-size: 12px;
}

.login-with-facebook:hover, .login-with-facebook:focus {
  background-color: #415dae;
  border: 1px solid #415dae;
}

.forgot-password {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #6e8496;
  text-align: left;
  text-decoration: none;
  float: left;
  line-height: 65px;
}

.forgot-password:hover {
  text-decoration: none;
}

.creat-an-acc {
  float: none;
  font-size: 14px;
  font-weight: 700;
  color: #7f878e;
  margin-top: 0px;
  display: block;
  text-align: center;
  padding: 50px 0px 0px;
  line-height: 0;
}

.password-icon {
  color: #2d7df5;
}

.password-icon i {
  position: absolute;
  right: 0px;
  top: 0px;
}

.reset-password-icon :global .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root span i {
  position: absolute;
  right: 0px;
  top: 0px;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .login-main {
    width: 90%;
    margin: 30px auto 20px !important;
    position: relative;
    transform: translateY(0%);
    left: 0%;
    top: 0%;
    margin-left: 0px;
    text-align: center;
  }
  .login-main h1 {
    padding: 15px 0px;
  }
  .login-main :global .MuiContainer-root {
    padding: 10px 10px 30px;
  }
  .login-with-facebook {
    margin-bottom: 10px;
  }
  .login-main .mb-forgot-password {
    line-height: 65px;
    text-align: center;
    width: 100%;
  }
}

.login-main {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  box-shadow: 0px 1px 2px 0px rgb(17 33 104 / 82%), 1px 2px 4px 0px rgb(17 33 104), 2px 4px 8px 0px rgb(255 255 255), 2px 4px 16px 0px rgb(46 59 122);
  padding: 40px 20px;
  border-radius: 10px;
  width: 500px;
  height: 500px;
}

.MuiContainer-root {
  height: 100%;
}

.makeStyles-paper-2 {
  height: 100%;
}

.login-main .MuiContainer-root {
  background: none !important;
  box-shadow: unset !important;
}
.login-main .form-wrap .MuiFormControl-root {
  margin-bottom: 40px !important;
}

.inputUser{
  font-family: Poppins-Regular;
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: 0 0;
    padding: 0 5px 0 38px;
}